<template>
  <v-container>
    <v-slide-y-transition>
      <mex-home-card :useHeaderTitle="false" headerColor="transparent" headerImage="LicenseServer_Title.png" style="margin-top: 200px">
        <template v-slot:headerImage>
          <v-img :max-height="550" :max-width="450" :src="require(`../assets/LicenseServer_TitleText_white.png`)" style="z-index: 0" />
        </template>
        <template v-slot:reveal-actions>
          <v-row align="center" justify="center">
            <div class="mx-2">
              <v-btn large @click="goToHome">
                <v-icon left>mdi-home</v-icon>
                Home
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn large @click="goToLogin">
                <v-icon left>mdi-fingerprint</v-icon>
                Login
              </v-btn>
            </div>
          </v-row>
        </template>
      </mex-home-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import SystemUsersService from '../services/systemUsers.service';

export default {
  name: 'Logout',
  components: {
    MexHomeCard: () => import('../components/MedITEX_Vue_Components/MexComponents/MexHomeCard'),
  },
  created() {
    this.$store.commit('sysAuth/logout');
    SystemUsersService.systemUserLogout();
  },
  methods: {
    goToLogin() {
      this.$router.push('/pages/login2FA');
    },
    goToHome() {
      this.$router.push('/');
    },
  },
};
</script>
